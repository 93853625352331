<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Use a color wheel to determine the solution colors from the color absorbed.
      </p>

      <table>
        <tr>
          <td>Green light absorbed:</td>
          <td>
            <v-select
              v-model="inputs.input1"
              class="ml-3"
              style="display: inline-block; max-width: 150px"
              :items="options"
              item-text="text"
              item-value="value"
              label="Select Option"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>

        <tr>
          <td>Blue light absorbed:</td>
          <td>
            <v-select
              v-model="inputs.input2"
              class="ml-3"
              style="display: inline-block; max-width: 150px"
              :items="options"
              item-text="text"
              item-value="value"
              label="Select Option"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>
        <tr>
          <td>Violet light absorbed:</td>
          <td>
            <v-select
              v-model="inputs.input3"
              class="ml-3"
              style="display: inline-block; max-width: 150px"
              :items="options"
              item-text="text"
              item-value="value"
              label="Select Option"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>

        <tr>
          <td>Red light absorbed:</td>
          <td>
            <v-select
              v-model="inputs.input4"
              class="ml-3"
              style="display: inline-block; max-width: 150px"
              :items="options"
              item-text="text"
              item-value="value"
              label="Select Option"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>

        <tr>
          <td>Orange light absorbed:</td>
          <td>
            <v-select
              v-model="inputs.input5"
              class="ml-3"
              style="display: inline-block; max-width: 150px"
              :items="options"
              item-text="text"
              item-value="value"
              label="Select Option"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>

        <tr>
          <td>Yellow light absorbed:</td>
          <td>
            <v-select
              v-model="inputs.input6"
              class="ml-3"
              style="display: inline-block; max-width: 150px"
              :items="options"
              item-text="text"
              item-value="value"
              label="Select Option"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S1Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      options: [
        {text: 'Green', value: 'green'},
        {text: 'Blue', value: 'blue'},
        {text: 'Violet', value: 'violet'},
        {text: 'Red', value: 'red'},
        {text: 'Orange', value: 'orange'},
        {text: 'Yellow', value: 'yellow'},
      ],
    };
  },
};
</script>
<style scoped></style>
