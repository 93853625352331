var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Use a color wheel to determine the solution colors from the color absorbed. ")]),_c('table',[_c('tr',[_c('td',[_vm._v("Green light absorbed:")]),_c('td',[_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options,"item-text":"text","item-value":"value","label":"Select Option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1)]),_c('tr',[_c('td',[_vm._v("Blue light absorbed:")]),_c('td',[_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options,"item-text":"text","item-value":"value","label":"Select Option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1)]),_c('tr',[_c('td',[_vm._v("Violet light absorbed:")]),_c('td',[_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options,"item-text":"text","item-value":"value","label":"Select Option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1)]),_c('tr',[_c('td',[_vm._v("Red light absorbed:")]),_c('td',[_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options,"item-text":"text","item-value":"value","label":"Select Option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1)]),_c('tr',[_c('td',[_vm._v("Orange light absorbed:")]),_c('td',[_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options,"item-text":"text","item-value":"value","label":"Select Option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1)]),_c('tr',[_c('td',[_vm._v("Yellow light absorbed:")]),_c('td',[_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options,"item-text":"text","item-value":"value","label":"Select Option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }